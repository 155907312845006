<template>
  <div
    class="me"
    :class="busy ? 'busy' : ''"
    :style="style"
    v-if="control.synopticComponent"
  >
    <div
      class="input-group"
      :style="{
        display:
          $utils.isFirefox() && parseInt($utils.navigatorVersion()) < 95
            ? 'table'
            : 'flex'
      }"
    >
      <input
        ref="sliderValue"
        type="range"
        class="slider"
        v-model="sliderValue"
        :min="min"
        :max="max"
        :step="inputStep"
        :disabled="busy || isDisabled || mode == 'editor'"
        @click="setFocus(true)"
      />
      <div class="label-value" v-if="showLabel" :style="labelStyle">
        <span ref="labelValue" :style="labelValueStyle">{{ labelValue }}</span>
      </div>
      <SynopticDataValueTip
        :entry="lastData"
        :value="value"
        :error="error"
        :position="control.synopticComponent.commandStatus"
        :hasPendingCommand="hasPendingCommand"
      />
    </div>
  </div>
</template>

<script>
import {debounce} from "lodash";
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import SynopticDataValueTip from "./synoptic-data-value-tip.vue";
export default {
  name: "SynopticDataValueSlider",
  extends: SynopticDataValueBase,
  components: {
    SynopticDataValueTip
  },
  data() {
    return {inpValue: undefined, sliding: false};
  },
  computed: {
    sliderValue: {
      set(value) {
        this.inpValue = parseFloat(value);
        this._delayedSetter();
        // this.value = this.inpValue;
        this.sliding = true;
        clearTimeout(this._delay);
        this._delay = setTimeout(
          () => {
            clearTimeout(this._delay);
            this._delay = null;
            this.$nextTick(() => {
              this.sliding = false;
              if (this.isDirty && this.isValid && !this.busy) {
                this.save();
                this.setFocus(false);
              }
            });
          },
          this.delayBeforeSave,
          this
        );
      },
      get() {
        return this.inpValue ?? this.iValue;
      }
    },
    iconRestoreStyle() {
      let r = {
        "font-size": this.inputStyle["font-size"],
        color: this.fgColor,
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "65%"
      };
      return r;
    },
    iconSaveStyle() {
      let r = {
        ...this.iconRestoreStyle,
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "65%"
      };
      if (!this.isValid || !this.hasWritePermission) {
        r["color"] = "#dcdcdc";
      }
      return r;
    },
    style() {
      var sliderStyle = {
        ...{
          "background-color": "#d3d3d3",
          color: "#333",
          height: "50%"
        },
        ...(this?.control?.synopticComponent?.sliderStyle ?? {})
      };
      var sliderHeight =
        (parseInt(sliderStyle["height"].replace(/\D/g, "")) / 100) *
        parseInt(this.currentRect.height);
      var handlerHeight = sliderHeight + 10;
      let r = {
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        border: this.controlStyle.border,
        "border-radius": this.controlStyle["border-radius"] ?? "3px",
        "background-color": this.bgColor,
        padding: this.controlStyle.padding || "0",
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "70%",
        "--slider-height": `${sliderHeight}px`,
        "--slider-handler-height": `${handlerHeight}px`,
        "--slider-handler-color": sliderStyle["color"],
        "--slider-background-color": sliderStyle["background-color"]
      };
      if (this.tmp && this.tmp.style) {
        Object.assign(r, this.tmp.style);
      }
      return r;
    },
    saveOnTab() {
      if (!("saveOnTab" in this?.control?.synopticComponent)) {
        return true; // legacy does not have this attribute
      }
      return this?.control?.synopticComponent?.saveOnTab;
    },
    maxSize() {
      return this.$root.$formatter.maxInputLength(this.lastData);
    },
    inputStep() {
      if (this.isDuration || this.isString) return undefined;
      let vlr = parseInt(this.control.synopticComponent.inputStep ?? "");
      return isNaN(vlr) || vlr <= 1 ? 1 : vlr;
    },
    fgColor() {
      return this.controlStyle["color"] || "#333";
    },
    bgColor() {
      return this.controlStyle["background-color"] || "transparent";
    },
    delayBeforeSave() {
      const v = parseInt(
        this?.control?.synopticComponent?.delayBeforeSave ?? 3000
      );
      return isNaN(v) ? 3000 : v;
    },
    showLabel() {
      return this.labelValue !== "";
    },
    labelValue() {
      let exp = this?.control?.synopticComponent?.expression || "";
      let fmt = this?.control?.format ? `|${this?.control?.format}` : "";
      let vlr = "";
      if (fmt) {
        if (!exp) exp = "${data?.value}";
        vlr = this.$root.$formatter.format({
          template: `${exp}${fmt}`,
          value: this.sliderValue
        });
      }
      return vlr === "" || vlr == null ? "" : vlr;
    },
    labelStyle() {
      // default source of style
      let src = this?.control?.synopticComponent?.style ?? {};
      // default text properties
      let s = {
        position: "absolute",
        "pointer-events": "none",
        width: "100%",
        "white-space": "nowrap",
        transform: `rotate(-${this?.control?.synopticComponent?.rotation ??
          0}deg)`
      };
      [
        "font-family",
        "font-size",
        "font-style",
        "font-weight",
        "text-decoration"
      ].forEach((p) => (s[p] = src[p]));
      // horizontal
      const hAlign = src["text-align"];
      if (hAlign == "justify") {
        const p = this.calcPerc(
          parseInt(this.sliderValue) || 0,
          this.min,
          this.max
        );
        if (p < 100) {
          let lw = this?.$refs?.labelValue?.offsetWidth ?? 0;
          const w = lw ? `${lw}px` : `0.65em`;
          s["padding-left"] = p ? `calc((100% - ${w}) * ${p / 100}` : "0";
        } else {
          s["text-align"] = "right";
        }
      } else {
        s["text-align"] = hAlign;
      }
      // vertical
      const vAlign = src["vertical-align"];
      if (vAlign == "top") {
        s["top"] = "0";
      } else if (vAlign == "bottom") {
        s["bottom"] = "0";
      }
      return s;
    },
    labelValueStyle() {
      return {
        "background-color": "transparent",
        color: this.controlStyle["color"] || "#333",
        opacity: this.sliding ? ".4" : "1"
      };
    }
  },
  methods: {
    setFocus(hasFocus) {
      this.hasFocus = hasFocus;
    },
    reset($e) {
      this.restore(false);
      this.setFocus(false);
      if ($e && $e.target && $e.target.blur) {
        $e.target.blur();
      }
    },
    onStep($e) {
      if (this.inputStep === undefined) return;
      this.value =
        parseFloat(this.iValue) + ($e > 0 ? this.inputStep : -this.inputStep);
    },
    restore(focus) {
      this.value = this.rawValue;
      this.inpValue = this.iValue;
      this.dataChange();
    }
  },
  beforeCreate() {
    this._delay = null;
    this._delayedSetter = debounce(() => {
      this.manuallyChanged = this.rawValue != this.inpValue;
      this.value = this.inpValue;
    }, 100);
  }
};
</script>

<style scoped>
.me {
  z-index: inherit;
  /* -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust); */
}

input,
select {
  border: 0;
  z-index: inherit;
}

.input-group .form-control {
  z-index: inherit;
  flex: 4;
}

.input-group {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.input-group-addon {
  background: transparent;
  border: 0;
  padding: 3px;
  color: "#696969";
  flex: 1;
  /* -webkit-text-size-adjust: var(--text-size-adjust); */
}

.input-group-addon:hover {
  background: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.busy {
  opacity: 0.5;
}

.busy:hover {
  cursor: wait;
}

.slider:hover {
  opacity: 1;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  background: var(--slider-background-color);
  outline: none;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  min-height: 10px;
  max-height: 100%;
  height: var(--slider-height);
}

.slider::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  border-radius: 5px;
  background: var(--slider-handler-color);
  min-height: 10px;
  max-height: 100%;
  height: var(--slider-handler-height);
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  width: 15px;
  appearance: none;
  border-radius: 5px;
  background: var(--slider-handler-color);
  min-height: 10px;
  max-height: 100%;
  height: var(--slider-handler-height);
}

.top-left::v-deep {
  top: 0;
  left: 2px;
}
.top-right::v-deep {
  top: 0;
  right: 2px;
}
.top-center::v-deep {
  top: 0;
  right: 50%;
}
.bottom-left::v-deep {
  bottom: 0;
  left: 2px;
}
.bottom-right::v-deep {
  bottom: 0;
  right: 2px;
}
.bottom-center::v-deep {
  bottom: 0;
  right: 50%;
}
</style>
