<script>
import SynopticEquipmentDataControl from "@/components/synoptic/synoptic-equipment-data-control.vue";

export default {
  name: "SynopticEquipmentDataControlBase",
  extends: SynopticEquipmentDataControl,
  computed: {
    formatList() {
      return this.$root.config.references.data_value_format_types || [];
    },
    textList() {
      if (!this?.lastData?.text_list?.id) return null;
      return (this.$root.config.references.text_lists || []).find(
        ({id}) => id == this.lastData.text_list.id || ""
      );
    },
    dataFormat() {
      if (!this.lastData) return null;
      return this.formatList.find(
        ({id}) => id == this.lastData?.value_format_type?.id || ""
      );
    },
    mode() {
      return this.$store.getters["dashboard/mode"] || "viewer";
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = Object.assign({}, this.control.synopticComponent.style, {});
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return {
        ...style,
        "border-radius": style["border-radius"] ?? "3px",
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`
      };
    },
    timestamp() {
      return this?.lastData?.current_value?.date_time || "";
    }
  },
  watch: {
    timestamp: {
      handler(n, o) {
        if (n == o) return;
        this.dataChange();
      },
      immediate: true
    },
    lastData: {
      handler(n) {
        if (n) {
          // console.log(n?.current_value?.value);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  methods: {
    equipmentData(id) {
      return (
        (this.$store.getters["dashboard/dataList"] || []).find(
          (i) => i.id == id
        ) || null
      );
    },
    dataChange() {
      this.$emit(
        "dataChange",
        this.$root.$formatter.describe(this.lastData, {
          format: this?.control?.format,
          expression: this?.expression,
          hint: this?.control?.synopticComponent?.hint,
          error: this.error
        })
      );
    }
  }
};
</script>
